<template>
  <BaseTableWrapper
    :special-text-formatting="specialTextFormatting"
    :table-init="tableInit"
    :is-admin="isAdmin"
    :columns="columns"
    :initial-filters="initialFilters"
  >
    <h1 class="page-header"></h1>
  </BaseTableWrapper>
</template>

<script>
import ticket from '@/services/ticket'
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import { friendlyDate } from '@/utils/time'
import GenericActionableColumn from '@/components/GenericActionableColumn.vue'
import { authComputed } from '@/state/helpers'
import {
  userPredefined,
  calculatedValues,
  datePredefined,
} from '@/utils/predefined'
import { deepClone } from '@/utils/deepClone'

export default {
  components: {
    BaseTableWrapper,
  },
  props: {
    ticketIdFilter: {
      type: [Number, String],
      default: null,
    },
    customerId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      initialFilters: [],
      isAdmin: false,
      specialTextFormatting: {
        createdAt: friendlyDate,
      },
      columns: [],
      tableInit: {
        enableSavedViews: false,
        enableStatusFilterToggle: false,
        enableColumnConfig: false,
        detailKeyId: 'ticketId',
        tableId: 'customer_details_tickets_tv_view',
        addNewEnabled: false,
        addNewHandler: () => this.$router.push({ name: 'tickets.add' }),
        currentPage: 1,
        perPage: 10,
        calculatedValues,
        initialFilters: [],
        columns: [],
        collection: 'Tickets',
        action: (params) => ticket.tableview(params),
      },
    }
  },
  computed: {
    ...authComputed,
  },
  async mounted() {
    const user = this.currentUser
    this.isAdmin = user?.group?.key === 'admin'
    await this.getColumns()
    if (this.ticketIdFilter) {
      const ticketFilter = {
        column: this.columns.find(
          (column) => column._t_id === 'ticket_id_filter'
        ),
        value: this.ticketIdFilter,
        hideOnFilterBar: true,
      }

      this.initialFilters = [ticketFilter]
    }
    this.initialFilters.push({
      column: this.columns.find(
        (column) => column._t_id === 'reservation_ticket_filter'
      ),
      value: this.customerId,
      hideOnFilterBar: true,
    })
  },
  methods: {
    receiveFilters(filters) {
      this.filters = filters
    },
    receiveSorts(sorts) {
      this.sorts = sorts
    },
    async getColumns() {
      const columnsOut = [
        {
          _t_id: '9a4b0d9e',
          prop: 'ticketId',
          text: 'Ticket #',
          displayType: 'clickable-ticket',
          sort: true,
          filter: true,
          component: GenericActionableColumn,
          type: 'text',
          sortProp: 'ticketId',
          filterType: 'eq',
          defaultSort: true,
        },
        {
          _t_id: 'ac4ce1b6',
          prop: 'ticketType',
          text: 'Type',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'eq',
          sortProp: 'ticketType',
        },
        {
          _t_id: 'customer_id_filter',
          filterProp: 'assignedTo.userId',
          filterType: 'eq',
          type: 'text',
          filterPlaceholder: true,
        },
      ]

      columnsOut.push({
        _t_id: '127fd3a4',
        prop: 'managedId',
        text: 'Reservation',
        displayType: 'clickable-reservation-broker',
        action: 'NOOP',
        sort: true,
        filter: true,
        type: 'text',
        filterType: 'contains',
        sortProp: 'reservation/managedId',
        filterProp: 'reservation/managedId',
      })

      columnsOut.push(
        {
          _t_id: 'c40de050',
          prop: 'status',
          text: 'Status',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'eq',
          sortProp: 'status',
        },
        {
          _t_id: '3ba33e65',
          prop: ['assigneeFirstName', 'assigneeLastName'],
          text: 'Assignee',
          sort: true,
          filter: true,
          type: 'text',
          sortProp: 'assigneeLastName',
          filterType: 'contains',
          predefined: userPredefined,
        },
        {
          _t_id: '3c9d04ae',
          prop: ['creatorFirstName', 'creatorLastName'],
          text: 'Creator',
          sort: true,
          filter: true,
          type: 'text',
          sortProp: 'creatorLastName',
          filterType: 'contains',
          predefined: userPredefined,
        },
        {
          _t_id: '0b594004',
          prop: 'createdAt',
          text: 'Created Date',
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          predefined: deepClone(datePredefined),
          sortProp: 'createdAt',
        },
        {
          _t_id: 'dc7e4286',
          prop: 'severity',
          text: 'Severity',
          component: GenericActionableColumn,
          displayType: 'severity',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'eq',
          sortProp: 'severity',
        },
        {
          _t_id: 'ticket_id_filter',
          filterType: 'eq',
          prop: 'ticketId',
          filterPlaceholder: true,
        },
        {
          _t_id: 'reservation_ticket_filter',
          filterType: 'eq',
          filterProp: 'reservation.trip.customer.customerId',
        }
      )

      return (this.columns = columnsOut)
    },
  },
}
</script>
