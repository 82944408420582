<template>
  <v-container>
    <template v-if="row.action === 'CUSTOMER_DETAIL'">
      <div class="contact-detail">
        <v-card-title primary-title>
          <div>
            <div class="headline">
              {{ op(row, 'customer/firstName') }}
              {{ op(row, 'customer/lastName') }}
            </div>
            <span>
              Phone:
              <a @click="() => flexCall(row)">
                {{ phoneFormatFilter(op(row, 'customer/phone')) }}
              </a>
              <br />
            </span>
            <span>
              Email:
              <a :href="`mailto:${op(row, 'customerEmail')}`">
                {{ op(row, 'customer/email') }}
              </a>
              <br />
            </span>
            <span
              v-if="op(row, 'customer/isBanned')"
              :style="`color: ${$cr.theme.error}`"
            >
              Banned
              <br />
            </span>
            <div>
              <div>
                Organization: {{ op(row, 'customer/organization') || '---' }}
              </div>
              <div>
                Customer Account:
                {{ op(row, 'customer/customerAccount/name') || '---' }}
              </div>
            </div>
            <div style="max-width: 600px">
              Customer Notes: {{ customerNote }}
            </div>
          </div>
        </v-card-title>
      </div>
    </template>
    <template v-if="row.action === 'BOOKED_BY_DETAIL'">
      <div class="contact-detail">
        <v-card-title primary-title>
          <div>
            <div class="headline">
              {{ op(row, 'createdByName') }}
            </div>

            <span>
              Email:
              <a :href="`mailto:${op(row, 'createdByEmail')}`">
                {{ op(row, 'createdByEmail') }}
              </a>
              <br />
            </span>
          </div>
        </v-card-title>
      </div>
    </template>
    <template v-if="row.action === 'DELETE_QUOTE'">
      <div>
        <div>
          <div>
            <div class="headline">Delete Quote</div>
            <span>Are you sure you would like to delete this quote?</span>
            <br />
            <br />
          </div>
        </div>
        <div class="column is-5" />
        <div class="column">
          <div class="unpad-buttons">
            <v-btn class="btn-secondaryaction" @click="cancel">Cancel</v-btn>
            <v-btn
              id="quote-details-button-delete"
              color="error"
              @click="deleteQuote"
            >
              Delete Quote
            </v-btn>
          </div>
        </div>
      </div>
    </template>
    <template v-if="row.action === 'QUOTE_SOLD_OUT'">
      <div>
        <div>
          <div>
            <div class="headline">Mark as Sold Out</div>
            <span>
              Are you sure you would like to mark this quote as sold out?
            </span>
            <br />
            <br />
          </div>
        </div>
        <div class="column is-5" />
        <div class="column">
          <div class="unpad-buttons">
            <v-btn class="btn-secondaryaction" @click="cancel">Cancel</v-btn>
            <v-btn
              id="quote-details-button-delete"
              color="error"
              @click="markAsSoldOut"
            >
              Mark as Sold Out
            </v-btn>
          </div>
        </div>
      </div>
    </template>
    <template v-if="row.action === 'QUOTE_PENDING_CONFIRMATION'">
      <div>
        <div>
          <div v-if="!row.isPendingConfirmation">
            <div class="headline">Mark Pending Confirmation</div>
            <span>
              Are you sure you would like to mark as pending confirmation?
            </span>
            <br />
            <br />
          </div>
          <div v-if="row.isPendingConfirmation">
            <div class="headline">Remove Pending Confirmation</div>
            <span>
              Are you sure you would like to remove pending confirmation?
            </span>
            <br />
            <br />
          </div>
        </div>
        <div class="column is-5" />
        <div class="column">
          <div class="unpad-buttons">
            <v-btn class="btn-secondaryaction" @click="cancel">Cancel</v-btn>
            <v-btn
              id="quote-details-button-toggle-pending-confirmation"
              color="error"
              @click="togglePendingConfirmation"
            >
              Confirm
            </v-btn>
          </div>
        </div>
      </div>
    </template>
  </v-container>
</template>

<script>
import customers from '@/services/customers'
import { phoneFormatFilter } from '@/utils/phone'
import op from 'simple-object-path'
import { authComputed } from '@/state/helpers'
import * as logger from '@/utils/logger'
import { truncate } from '@/utils/string'

export default {
  // components: {
  //   'cr-quote-quick-view': QuoteQuickView,
  // },
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    props: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      reservation: {},
      loading: false,
      op,
      phoneFormatFilter,
    }
  },
  computed: {
    ...authComputed,
    customerNote() {
      if (this.row.customer?.customerNotes.length > 0) {
        return truncate(this.row.customer?.customerNotes?.[0].note, 180, true)
      }
      return ''
    },
  },
  async mounted() {
    this.$emit('row-expanded', this.props)
    if (this.row.action === 'BOOKED_BY_DETAIL') {
      await this.getUser(this.row.createdById)
    }
    if (this.row.action === 'CUSTOMER_DETAIL') {
      await this.getCustomer(this.row.customerId)
    }
  },
  methods: {
    async getUser(userId) {
      const userData = await this.$store.dispatch('users/getUser', userId)
      this.row = { ...this.row, createdByEmail: userData?.data?.user?.email }
    },
    async getCustomer(customerId) {
      const userData = await customers.getCustomer(customerId)
      this.row = { ...this.row, customer: userData?.data?.customer }
    },
    refreshQuery() {
      this.$emit('refresh-query-request')
    },
    deleteQuote() {
      const response = this.$store
        .dispatch('quotes/deleteQuote', this.row.quoteId)
        .catch((error) => {
          logger.warn(error)
          return false
        })
      if (response) {
        this.props.expanded = false
        this.row.deleteMe(this.row)
      }
    },
    markAsSoldOut() {
      const res = this.$store
        .dispatch('quotes/markSoldOut', this.row.quoteId)
        .catch((error) => {
          logger.warn(error)
          return false
        })
      if (res) {
        this.refreshQuery()
      }
    },
    async togglePendingConfirmation() {
      const res = await this.$store
        .dispatch('quotes/togglePendingConfirmation', this.row.quoteId)
        .catch((error) => {
          logger.warn(error)
          return false
        })
      if (res) {
        this.refreshQuery()
      }
    },
    cancel() {
      this.props.expanded = false
    },
    flexCall(row) {
      this.$store.dispatch('twilio/callNumber', row)
    },
  },
}
</script>

<style lang="scss">
.v-btn.remove-right-margin {
  margin-right: 0 !important;
}

.detail-container {
  padding: 4px;
  background-color: $blue-pale;
}

.detail {
  padding: 10px;
}

.half-width {
  width: 50%;
}

.center-contents {
  text-align: center;
}

.contact-detail {
  margin-top: 0;
}

.unpad-buttons button:first-child {
  margin-left: 0;
}

.float-right {
  float: right;
}

.has-border {
  border-top: 1px solid gray;
  border-left: 1px solid gray;

  &.top-right {
    border-right: 1px solid gray;
    border-top-right-radius: 4px;
  }

  &.top-left {
    border-top-left-radius: 4px;
  }

  &.bottom-left {
    border-bottom: 1px solid gray;
    border-bottom-left-radius: 4px;
  }

  &.bottom-right {
    border-right: 1px solid gray;
    border-bottom: 1px solid gray;
    border-bottom-right-radius: 4px;
  }
}
</style>
