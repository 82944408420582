<template>
  <div>
    <div class="main-style">
      <v-layout row>
        <v-flex :class="{ xs8: isMainContactEditEnabled, 'pr-5': true }">
          <v-layout justify-end>
            <v-flex shrink>
              <v-btn
                v-if="isModeEdit"
                id="customer-detail-crm-submit-button"
                :disabled="loading"
                :loading="loading"
                class="button-text-color mr-0"
                color="primary"
                @click="submit"
              >
                Save
              </v-btn>
              <v-btn
                v-if="isModeView"
                id="customer-detail-crm-edit-button"
                :disabled="loading || !canEdit"
                :loading="loading"
                class="button-text-color mr-0"
                color="primary"
                @click="enableEditing"
              >
                Edit
              </v-btn>
            </v-flex>
          </v-layout>

          <v-form ref="form">
            <v-layout wrap>
              <v-flex d-flex justify-center>
                <v-flex class="mt-1 mr-2">
                  Organization
                  <v-spacer xs2 />
                  <v-flex d-flex justify-center>
                    <CRInput
                      id="customer-detail-crm-organization-input"
                      v-model="customer.organization"
                      :disabled="isModeView"
                    />
                  </v-flex>
                </v-flex>
                <v-flex class="mt-1 ml-2">
                  Bookings per year
                  <v-spacer xs2 />
                  <v-flex d-flex justify-center>
                    <CRInput
                      id="customer-detail-crm-bookings-input"
                      v-model="customer.bookings"
                      :disabled="isModeView"
                    />
                  </v-flex>
                </v-flex>
              </v-flex>
              <v-flex xs12 class="mt-1">
                <IndustrySelector
                  ref="industrySelector"
                  v-model="customer.industryId"
                  :initial-industry="customer.industryId"
                  :industry-required="true"
                  :mode="mode"
                />
              </v-flex>
              <v-flex v-if="showIndustryDescription" xs12 class="mt-1">
                <CRInput
                  id="customer-detail-industry-description"
                  v-model="customer.industryDescription"
                  :rules="
                    showIndustryDescription
                      ? [
                          isRequired(true, isNotEmpty, {
                            req: 'Industry Description Is Required',
                            error: 'Industry Description Is Required',
                          }),
                        ]
                      : []
                  "
                  :disabled="isModeView"
                  name="Industry Description"
                  label="Industry Description"
                  placeholder="Industry Description"
                  cols="10"
                  rows="1"
                />
              </v-flex>
              <v-flex xs12 class="mt-1">Notes</v-flex>
              <v-flex xs12 class="mt-1">
                <CRRichText
                  v-for="(note, index) in customer.customerNotes"
                  :key="`customer-note-${index}`"
                  id="`customer-detail-crm-notes-input-${index}`"
                  :note="note.note"
                  solo
                  multi-line
                  flat
                  :disabled="isModeView"
                  @htmlchange="updateCustomerNoteHtml($event, index)"
                  @textchange="updateCustomerNoteText($event, index)"
                />
              </v-flex>
              <v-layout row>
                <v-flex xs4>
                  <v-checkbox
                    id="customer-detail-ban-checkbox"
                    v-model="customer.isBanned"
                    :disabled="isModeView"
                    name="Banned"
                    label="Banned"
                    hide-details
                    style="margin: 0px !important"
                  />
                </v-flex>
                <v-flex xs4>
                  <v-checkbox
                    id="customer-detail-quote-sms-enabled-checkbox"
                    v-model="customer.isQuoteFollowUpSMSEnabled"
                    :disabled="isModeView"
                    name="SMS Opt-In"
                    label="SMS Opt-In"
                    hide-details
                  />
                </v-flex>
              </v-layout>
            </v-layout>
          </v-form>
        </v-flex>
        <v-divider
          v-if="isMainContactEditEnabled"
          vertical
          style="margin-right: 20px"
        ></v-divider>
        <CustomerSignUpEmailDetail
          v-if="isMainContactEditEnabled"
          class="email-detail"
          :customer="customer"
          :show-signed-up="false"
        />
      </v-layout>
    </div>
  </div>
</template>

<script>
import customers from '@/services/customers'
import industries from '@/services/industries'
import { authComputed } from '@/state/helpers'
import { filter } from '@/utils/filter'
import { isNotEmpty, isRequired } from '@/utils/validators'
import IndustrySelector from '@/components/IndustrySelector.vue'
import { SplitFeatureFlag } from '@/utils/enum'
import { mapActions } from 'vuex'
import CustomerSignUpEmailDetail from './CustomerSignUpEmailDetail.vue'

export default {
  components: {
    IndustrySelector,
    CustomerSignUpEmailDetail,
  },
  props: {
    customerId: {
      type: String,
      default: null,
    },
    mode: {
      type: String,
      default: () => 'view',
    },
  },
  data() {
    return {
      loading: false,
      canEdit: false,
      showIndustryDescription: false,
      previousCustomerNotes: '',
      isMainContactEditEnabled: false,
      customer: {
        organization: '',
        bookings: null,
        industryId: null,
        industryDescription: '',
        notes: '',
        customerNotes: [],
        isBanned: false,
        isQuoteFollowUpSMSEnabled: true,
        customerCreatedOn: null,
      },
      isRequired,
      isNotEmpty,
    }
  },
  computed: {
    ...authComputed,
    industryId() {
      return this.customer.industryId
    },
    isModeView() {
      return this.mode === 'view'
    },
    isModeEdit() {
      return this.mode === 'edit'
    },
  },
  watch: {
    industryId() {
      this.shouldShowIndustryDescription()
    },
  },
  async mounted() {
    this.canEdit = this.hasPermission('canUpdateCustomer')
    this.isMainContactEditEnabled = await this.isFeatureEnabled(
      SplitFeatureFlag.MainContactEdit
    )
    await this.refreshCustomer()
  },
  methods: {
    ...mapActions({
      isFeatureEnabled: 'split/isFeatureEnabled',
    }),
    validate() {
      if (
        this.$refs.industrySelector &&
        !this.$refs.industrySelector.validate()
      ) {
        return false
      }
      return this.$refs.form.validate()
    },
    enableEditing() {
      this.mode = 'edit'
    },
    updateCustomerNoteHtml(value, index) {
      if (this.customer.customerNotes?.[index]) {
        this.customer.customerNotes[index].html = value
      } else {
        this.customer.customerNotes.push({ html: value })
      }
    },
    updateCustomerNoteText(value, index) {
      if (this.customer.customerNotes?.[index]) {
        this.customer.customerNotes[index].note = value
      } else {
        this.customer.customerNotes.push({ note: value })
      }
    },
    async shouldShowIndustryDescription() {
      const industryResponse = await industries.getIndustry(this.industryId)
      const industryData = industryResponse.data?.industry
      if (industryData.isOther) {
        this.showIndustryDescription = true
      } else {
        this.customer.industryDescription = ''
        this.showIndustryDescription = false
      }
    },
    async refreshCustomer() {
      const customerFilter = filter()
      const parentFilter = customerFilter.createParent('and')

      customerFilter.add(parentFilter, {
        column: {
          _t_id: 'customer_data_id',
          prop: 'customerId',
          filterType: 'eq',
        },
        value: this.customerId,
      })

      const customerTVRequest = customers.getCustomers({
        pageSize: 1,
        filters: customerFilter.asQueryParams(),
      })
      const customerDetailRequest = customers.getCustomer(this.customerId)
      const customerResponses = await Promise.all([
        customerTVRequest,
        customerDetailRequest,
      ])

      const customerData = customerResponses[0].data.resultList[0]
      const customerDetailData = customerResponses[1]

      const customerNotesData = customerDetailData.data
      const notes = customerNotesData.customer.notes
      const customerNotes = customerNotesData.customer.customerNotes
      const customerIndustryId = customerNotesData.customer.industryId
      const customerIndustryDescription =
        customerNotesData.customer.industryDescription
      customerData.notes = notes
      customerData.customerNotes = customerNotes
      this.previousCustomerNotes =
        customerNotes?.[0]?.html || customerNotes?.[0]?.note
      customerData.industryId = customerIndustryId
      customerData.industryDescription = customerIndustryDescription
      customerData.createdOn = customerNotesData.customer.createdOn
      customerData.forgotPasswordEmailSentAt =
        customerNotesData.customer.forgotPasswordEmailSentAt
      customerData.signUpEmailSentAt =
        customerNotesData.customer.signUpEmailSentAt
      customerData.passwordUpdatedAt =
        customerNotesData.customer.passwordUpdatedAt
      customerData.isQuoteFollowUpSMSEnabled = customerNotesData.customer?.quoteFollowUpNotificationSetting?.isEnabled
      this.customer = Object.assign({}, this.customer, customerData)
      this.customerCreatedOn = this.customer.createdOn
    },
    async submit() {
      if (!this.validate()) {
        return
      }
      const params = {}
      params.id = this.customerId
      params.payload = {
        organization: this.customer.organization,
        bookings: this.customer.bookings,
        notes: this.customer.notes,
        customerNotes: this.customer.customerNotes,
        industryId: this.customer.industryId,
        industryDescription: this.customer.industryDescription,
        isBanned: this.customer.isBanned,
        customerAccountId: this.customer.customerAccountId,
        isQuoteFollowUpSMSEnabled: this.customer.isQuoteFollowUpSMSEnabled
      }

      await customers.modifyCustomer(params)

      this.mode = 'view'
      await this.refreshCustomer()
      this.$emit('refresh-audit-table')
    },
  },
}
</script>

<style lang="scss" scoped>
.main-style {
  background-color: $white;
  color: $black;
  margin-top: 92px;
}
.label {
  color: $gray-medium-dark;
  font-weight: 700;
  margin-right: 10px;
}
.send-email-button {
  margin-bottom: 10px;
  cursor: pointer;
  color: $primary;
}
.email-detail ::v-deep .send-email-button {
  margin-left: 0px;
}
</style>
