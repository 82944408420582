<template>
  <div>
    <v-layout row class="mb-3">
      <v-flex col lg6>
        <h2 v-if="totalDue" class="stat-header">
          {{ totalDue | currencyFilter }}
        </h2>
        <h2 v-else class="stat-header">$0.00</h2>
        Total Due
      </v-flex>
      <v-flex col lg6 class="red-text">
        <h2 v-if="totalOverdue" class="stat-header">
          {{ totalOverdue | currencyFilter }}
        </h2>
        <h2 v-else class="stat-header">$0.00</h2>
        Total Overdue
      </v-flex>
    </v-layout>
    <v-divider />
    <v-layout row align-center justify-space-between class="mb-3">
      <v-flex v-if="reservationsToTripsRatio !== null" xs6>
        <h2 class="stat-header">
          {{ reservationsToTripsRatio | truncateRatio }}
        </h2>
        Conversion Rate
      </v-flex>
      <v-flex shrink>
        <v-dialog v-model="timeFrameModal" width="450">
          <template #activator="{ on }">
            <v-btn outline color="primary" v-on="on">
              {{ startDatetime | monthYearFilter }} -
              {{ endDatetime | monthYearFilter }}
            </v-btn>
          </template>

          <v-card class="cr-modal">
            <v-card-title>
              <h2>Date Range</h2>
            </v-card-title>
            <v-layout class="pa-5" padded row wrap justify-space-around>
              <v-flex xs6 class="pl-2">
                <CRInput
                  :id="`customer-detail-graphs-date-time-start-datetime`"
                  v-model="startDatetime"
                  label="Start Date"
                  type="date"
                />
              </v-flex>
              <v-flex xs6>
                <CRInput
                  :id="`customer-detail-graphs-date-time-end-datetime`"
                  v-model="endDatetime"
                  label="End Date"
                  type="date"
                />
              </v-flex>
              <v-spacer />
              <v-flex shrink>
                <v-btn
                  id="customer-detail-graphs-date-range-button-submit"
                  @click="timeFrameModal = false"
                >
                  OK
                </v-btn>
              </v-flex>
            </v-layout>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex col lg6 class="pb-2">
        <BarChart
          v-if="quoteCountData.labels"
          ref="quoteCount"
          :chartdata="quoteCountData"
          :styles="chartStyles"
          :options="chartOptions"
        />
        <h2 class="stat-header">{{ totalQuotesCount }}</h2>
        Quotes
      </v-flex>
      <v-flex col lg6 class="pb-2">
        <BarChart
          v-if="quoteValueData.labels"
          :chartdata="quoteValueData"
          :styles="chartStyles"
          :options="chartOptions"
        />
        <h2 class="stat-header">{{ totalQuotesValue | currencyFilter }}</h2>
        Quote Total
      </v-flex>
    </v-layout>
    <v-divider />
    <v-layout row wrap>
      <v-flex col lg6 class="pt-2 pb-2">
        <BarChart
          v-if="reservationCountData.labels"
          :chartdata="reservationCountData"
          :styles="chartStyles"
          :options="chartOptions"
        />
        <h2 class="stat-header">{{ totalReservationsCount }}</h2>
        Reservations
      </v-flex>
      <v-flex col lg6 class="pt-2 pb-2">
        <BarChart
          v-if="reservationsValueData.labels"
          :chartdata="reservationsValueData"
          :styles="chartStyles"
          :options="chartOptions"
        />
        <h2 class="stat-header">
          {{ totalReservationsValue | currencyFilter }}
        </h2>
        Reservation Total
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import BarChart from '@/components/BarChart.vue'
import { DateTime } from 'luxon'
import { currencyFilter } from '@/utils/currency'
import { planetChartData } from './mock-data'

export default {
  components: {
    BarChart,
  },
  filters: {
    monthYearFilter(dateTime) {
      return DateTime.fromISO(dateTime)?.toFormat('LLL yyyy')
    },
    currencyFilter,
    truncateRatio(val) {
      return `${Math.floor(val * 100)}%`
    },
  },
  props: {
    customerId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      chartStyles: {
        height: '180px',
        position: 'relative',
      },

      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
      },

      planetChartData,
      startDatetime: null,
      endDatetime: null,
      quotesData: null,
      reservationsData: null,
      timeFrameModal: false,

      totalQuotesCount: 0,
      totalTripsCount: 0,
      totalQuotesValue: 0,
      totalReservationsCount: 0,
      totalReservationsValue: 0,

      totalDue: null,
      totalOverdue: null,
    }
  },
  computed: {
    quoteCountData() {
      return {
        labels: this.quotesData?.map((data) => data.date),
        datasets: [
          {
            label: 'Count',
            backgroundColor: this.$cr.theme.primary,
            data: this.quotesData?.map((data) => data.count),
          },
        ],
      }
    },
    quoteValueData() {
      return {
        labels: this.quotesData?.map((data) => data.date),
        datasets: [
          {
            label: 'amount',
            backgroundColor: this.$cr.theme.primary,
            data: this.quotesData?.map((data) => data.amount),
          },
        ],
      }
    },
    reservationCountData() {
      return {
        labels: this.reservationsData?.map((data) => data.date),
        datasets: [
          {
            label: 'Count',
            backgroundColor: this.$cr.theme.primary,
            data: this.reservationsData?.map((data) => data.count),
          },
        ],
      }
    },
    reservationsValueData() {
      return {
        labels: this.reservationsData?.map((data) => data.date),
        datasets: [
          {
            label: 'Count',
            backgroundColor: this.$cr.theme.primary,
            data: this.reservationsData?.map((data) => data.amount),
          },
        ],
      }
    },
    reservationsToTripsRatio() {
      if (this.totalTripsCount !== 0) {
        return this.totalReservationsCount / this.totalTripsCount
      } else {
        return null
      }
    },
  },
  watch: {
    customerId: {
      async handler() {
        this.updateChartData()
      },
    },
    startDatetime() {
      this.updateChartData()
    },
    endDatetime() {
      this.updateChartData()
    },
  },
  mounted() {
    this.endDatetime = DateTime.local().toISODate()
    this.startDatetime = DateTime.local().minus({ year: 1 }).toISODate()
  },
  methods: {
    async updateChartData() {
      const quotesData = (
        await this.$store.dispatch('quotes/quoteTimeSeriesCustomerData', {
          customerId: this.customerId,
          payload: {
            startDatetime: this.startDatetime,
            endDatetime: this.endDatetime,
          },
        })
      )?.data

      this.quotesData = quotesData?.timeSeries
      this.totalQuotesCount = quotesData?.totalCount
      this.totalTripsCount = quotesData?.totalTripCount
      this.totalQuotesValue = quotesData?.totalValue

      const reservationsData = (
        await this.$store.dispatch(
          'reservations/reservationTimeSeriesCustomerData',
          {
            customerId: this.customerId,
            payload: {
              startDatetime: this.startDatetime,
              endDatetime: this.endDatetime,
            },
          }
        )
      )?.data
      this.reservationsData = reservationsData?.timeSeries
      this.totalReservationsCount = reservationsData?.totalCount
      this.totalReservationsValue = reservationsData?.totalValue

      const reservationBalanceSummary = await this.$store.dispatch(
        'reservations/getReservationBalanceSummary',
        {
          customerId: this.customerId,
        }
      )
      this.totalDue =
        reservationBalanceSummary?.data?.customerBalanceSummaryDTO?.totalBalance
      this.totalOverdue =
        reservationBalanceSummary?.data?.customerBalanceSummaryDTO?.totalOverdueBalance
    },
  },
}
</script>

<style lang="scss" scoped>
.stat-header {
  font-size: 28px;
  border-bottom: none;
}

.red-text {
  color: $error;
}
</style>
