var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-layout',{staticClass:"sheet customer-detail-wrapper"},[_c('div',{staticClass:"main-style pa-4",staticStyle:{"max-width":"100%"}},[_c('v-layout',{staticClass:"mb-4",attrs:{"row":"","align-center":"","justify-space-between":""}},[_c('v-flex',{attrs:{"xs6":""}},[_c('h1',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.customerName)+" "),_c('span',{class:`invite-status invite-status--${_vm.toKebab(
                _vm.customerInvitationStatus
              )}`},[_vm._v(" "+_vm._s(_vm.customerInvitationStatus)+" ")])])]),_c('v-flex',{staticClass:"justify-end",attrs:{"xs6":""}},[_c('v-btn',{staticClass:"back-style",attrs:{"outline":"","color":"primary"},on:{"click":_vm.goBack}},[_vm._v(" Back To All Customers ")])],1)],1),_c('v-divider'),_c('v-layout',{attrs:{"row":"","justify-start":""}},[_c('v-flex',{staticClass:"pr-5",attrs:{"xs5":""}},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_vm._v("First Name")]),_c('v-flex',{staticClass:"mt-1",attrs:{"xs12":""}},[_c('CRInput',{attrs:{"id":"customer-detail-first-name-input","disabled":_vm.disabled,"rules":[
                    _vm.isRequired(true, _vm.isNotEmpty, {
                      req: 'First Name Is Required',
                      error: 'First Name Is Required',
                    }),
                  ]},model:{value:(_vm.customer.firstName),callback:function ($$v) {_vm.$set(_vm.customer, "firstName", $$v)},expression:"customer.firstName"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_vm._v("Last Name")]),_c('v-flex',{staticClass:"mt-1",attrs:{"xs12":""}},[_c('CRInput',{attrs:{"id":"customer-detail-last-name-input","disabled":_vm.disabled,"rules":[
                    _vm.isRequired(true, _vm.isNotEmpty, {
                      req: 'Last Name Is Required',
                      error: 'Last Name Is Required',
                    }),
                  ]},model:{value:(_vm.customer.lastName),callback:function ($$v) {_vm.$set(_vm.customer, "lastName", $$v)},expression:"customer.lastName"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_vm._v("Email")]),_c('v-flex',{staticClass:"mt-1",attrs:{"xs12":""}},[_c('CRInput',{attrs:{"id":"customer-detail-email-input","disabled":_vm.disabled,"rules":[
                    _vm.isRequired(true, _vm.validateEmail, {
                      req: 'Email Required',
                      error: 'Email must be valid',
                    }),
                  ]},model:{value:(_vm.customer.email),callback:function ($$v) {_vm.$set(_vm.customer, "email", $$v)},expression:"customer.email"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_vm._v("Phone")]),_c('v-flex',{staticClass:"mt-1",attrs:{"xs12":""}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['(###) ###-####', '+## ## #### ####']),expression:"['(###) ###-####', '+## ## #### ####']"}],attrs:{"id":"customer-detail-phone-input","disabled":_vm.disabled,"flat":"","solo":"","rules":[
                    _vm.isRequired(true, _vm.isNotEmpty, {
                      req: 'Phone Number Is Required',
                      error: 'Phone Number Is Required',
                    }),
                  ]},model:{value:(_vm.customer.phone),callback:function ($$v) {_vm.$set(_vm.customer, "phone", $$v)},expression:"customer.phone"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_vm._v("Title")]),_c('v-flex',{staticClass:"mt-1",attrs:{"xs12":""}},[_c('v-text-field',{attrs:{"id":"customer-detail-title-input","disabled":_vm.disabled,"flat":"","solo":""},model:{value:(_vm.customer.title),callback:function ($$v) {_vm.$set(_vm.customer, "title", $$v)},expression:"customer.title"}})],1),_c('v-flex',{staticClass:"mt-1",attrs:{"xs12":""}},[_c('div',[_c('CustomerAccountSelector',{attrs:{"customer-account-id":_vm.customer.customerAccountId,"disabled":_vm.disabled,"label":"Customer Account","placeholder":"Type to search","clearable":"","link-detail":"","rules":[
                      _vm.isRequired(true, _vm.isNotEmpty, {
                        req: 'Customer Account Is Required',
                        error: 'Customer Account Is Required',
                      }),
                    ]},on:{"customer-account-selected":_vm.selectCustomerAccountId,"customer-account-cleared":_vm.clearCustomerAccountId}})],1)]),_c('v-flex',{attrs:{"xs12":""}},[_vm._v("Roles")]),_c('v-flex',{staticClass:"mt-1",attrs:{"xs12":""}},[_c('v-select',{attrs:{"id":"customer-detail-roles-input","disabled":_vm.disabled,"items":[
                    { text: 'Admin', value: 'customer_account_admin' },
                  ],"multiple":"","chips":"","solo":"","flat":""},model:{value:(_vm.customer.userRoleNames),callback:function ($$v) {_vm.$set(_vm.customer, "userRoleNames", $$v)},expression:"customer.userRoleNames"}})],1),_c('v-layout',{attrs:{"justify-end":""}},[_c('v-flex',{attrs:{"shrink":""}},[_c('v-btn',{staticClass:"button-text-color mr-0",attrs:{"id":"customer-detail-submit-button","disabled":_vm.loading || _vm.disabled || !_vm.valid,"loading":_vm.loading,"color":"primary","type":"submit"},on:{"click":_vm.submit}},[_vm._v(" Save Changes ")])],1)],1)],1)],1)],1),_c('v-flex',{attrs:{"shrink":""}},[_c('v-divider',{attrs:{"vertical":""}})],1),_c('v-flex',{staticClass:"ml-5 mt-3",attrs:{"xs7":""}},[_c('CustomerDetailGraphs',{attrs:{"customer-id":_vm.id}})],1)],1),_c('v-divider',{staticClass:"mt-4"}),_c('v-layout',{ref:"customerDetailTables",staticClass:"parent-scroll",attrs:{"row":""}},[_c('v-flex',{staticClass:"scrollable-table",attrs:{"xs12":""}},[_c('CustomerDetailTables',{attrs:{"customer-id":_vm.id},on:{"scroll-to-table":_vm.scrollToBottom}})],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }