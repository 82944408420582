<template>
  <v-container fluid>
    <v-layout column sheet>
      <DataTable v-bind="tableProps" />
    </v-layout>
  </v-container>
</template>

<script>
import DataTable from '@/components/DataTable.vue'
import QuotesListDetail from '@/components/QuotesListDetail.vue'
import QuotesActionableColumn from '@/components/QuotesActionableColumn.vue'
import { DateTime } from 'luxon'
import * as logger from '@/utils/logger'
import { currencyFilter } from '@/utils/currency'
import { deepClone } from '@/utils/deepClone'
import { authComputed } from '@/state/helpers'
import {
  calculatedValues,
  datePredefined,
  numericRangePredefined,
  textLike,
  textLikeOrExactForArrayProps,
  userPredefined,
} from '@/utils/predefined'

export default {
  components: {
    DataTable,
  },
  props: {
    customerId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      debounce: undefined,
      filters: () => [],
      sorts: () => [],
      categoryParentId: undefined,
      selectedCategory: undefined,
      itemsPerPage: 10,
      page: 1,
      tableProps: {
        enableExport: false,
        enableColumnConfig: false,
        enableSavedViews: false,
        enableStatusFilterToggle: true,
        tableId: 'customer_details_quotes_table_view',
        total: 0,
        currentPage: 1,
        perPage: 10,
        list: [],
        sort: this.sort,
        changePage: this.changePage,
        addFilter: this.addFilter,
        removeFilter: this.removeFilter,
        initialFilters: [],
        detail: QuotesListDetail,
        detailKeyId: 'quoteId',
        isDetailed: false,
        addNewEnabled: false,
        addNewHandler: () => {
          this.$router.push({ name: 'quotes.add' })
        },
        shareFilters: this.receiveFilters,
        shareSorts: this.receiveSorts,
        loading: true,
        calculatedValues,
        columns: [
          {
            _t_id: 'f4dfd5e0',
            prop: 'quoteId',
            text: 'ID',
            component: QuotesActionableColumn,
            sort: true,
            filter: true,
            detail: false,
            type: 'number',
            displayType: 'clickable-link-new',
            filterType: 'eq',
          },
          {
            _t_id: 'f4dfd72a',
            prop: ['createdBy/firstName', 'createdBy/lastName'],
            text: 'Sent By',
            sort: true,
            filter: true,
            detail: false,
            type: 'text',
            action: 'BOOKED_BY_DETAIL',
            displayType: 'action-item',
            sortProp: 'createdBy/lastName',
            filterType: 'contains',
            childMethod: 'and',
            predefined: userPredefined,
          },
          {
            _t_id: 'f4dfd856',
            prop: 'tripTypes',
            text: 'Trip Types',
            sort: true,
            filter: true,
            component: QuotesActionableColumn,
            detail: false,
            type: 'text',
            displayType: 'trip-type-multi',
            sortProp: 'tripTypes/key',
            filterProp: 'tripTypes/key',
            filterType: 'contains',
          },
          {
            _t_id: 'f4dfd978',
            prop: 'createdOn',
            text: 'Created On',
            computedText: (item) =>
              DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
            sort: true,
            filter: true,
            type: 'date',
            method: 'and',
            childMethod: 'and',
            filterType: 'eq',
            predefined: deepClone(datePredefined),
            defaultSort: true,
          },
          {
            _t_id: 'f4dfdd06',
            prop: 'convertedOn',
            text: 'Converted On',
            computedText: (item) =>
              item === null
                ? ''
                : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
            sort: true,
            filter: true,
            type: 'date',
            method: 'and',
            childMethod: 'and',
            filterType: 'eq',
            predefined: deepClone(datePredefined),
          },
          {
            _t_id: 'f4dfde64',
            prop: 'customerName',
            text: 'Customer',
            sort: true,
            filter: true,
            type: 'text',
            component: QuotesActionableColumn,
            action: 'CUSTOMER_DETAIL',
            detail: false,
            displayType: 'action-item',
            sortProp: 'customer/lastName',
            filterType: 'contains',
            childMethod: 'and',
            predefined: textLikeOrExactForArrayProps,
          },
          {
            _t_id: 'f4d3de64',
            prop: ['customer/customerAccount/name'],
            text: 'Customer Account',
            sort: true,
            filter: true,
            type: 'text',
            action: 'CUSTOMER_DETAIL',
            detail: false,
            displayType: 'action-item',
            sortProp: 'customer/customerAccount/name',
            filterType: 'contains',
            childMethod: 'and',
            predefined: textLike,
          },
          {
            _t_id: '1711f7ce',
            prop: 'amount',
            text: 'Amount',
            computedText: (item) => currencyFilter(item),
            sort: true,
            filter: true,
            type: 'number',
            sortProp: 'amount',
            filterType: 'eq',
            childMethod: 'and',
            predefined: deepClone(numericRangePredefined),
          },
          {
            _t_id: '1a25db55',
            prop: 'pickupLocation',
            text: 'Pickup Location',
            sort: true,
            filter: true,
            type: 'text',
            filterType: 'contains',
            filterProp: 'pickupLocation',
            sortProp: 'pickupLocation',
          },
          {
            _t_id: 'adcdd422',
            prop: 'paymentTypes',
            text: 'Payment Types',
            sort: true,
            filter: true,
            component: QuotesActionableColumn,
            detail: false,
            type: 'text',
            sortProp: 'paymentTypes/key',
            displayType: 'payment-type-multi',
            filterProp: 'paymentTypes/key',
            filterType: 'contains',
            defaultHidden: true,
          },
          {
            _t_id: 'adcdd300',
            prop: 'paymentMethodTypes',
            text: 'Payment Methods',
            sort: true,
            filter: true,
            component: QuotesActionableColumn,
            detail: false,
            type: 'text',
            sortProp: 'paymentMethodTypes/key',
            displayType: 'payment-method-type-multi',
            filterProp: 'paymentMethodTypes/key',
            filterType: 'contains',
            defaultHidden: true,
          },
          {
            _t_id: 'adcdd1ca',
            prop: 'checkoutPage/label',
            text: 'Checkout Page',
            sort: true,
            filter: true,
            type: 'text',
            sortProp: 'checkoutPage/key',
            filterProp: 'checkoutPage/key',
            filterType: 'contains',
            defaultHidden: false,
          },
          {
            _t_id: 'adcdce3c',
            prop: 'leadSourceType/label',
            text: 'Lead Source',
            sort: true,
            filter: true,
            type: 'text',
            sortProp: 'leadSourceType/key',
            filterProp: 'leadSourceType/key',
            filterType: 'contains',
            defaultHidden: true,
          },
          {
            _t_id: 'adcdccfc',
            prop: 'reservations',
            text: 'Reservations',
            sort: true,
            filter: true,
            component: QuotesActionableColumn,
            type: 'text',
            sortProp: 'reservations',
            detail: false,
            displayType: 'res-link',
            filterProp: 'reservations',
            filterType: 'contains',
            defaultHidden: true,
          },
          {
            _t_id: 'b7bcbc26',
            prop: 'dropoffDate',
            text: 'Dropoff Date',
            computedText: (item) =>
              item === null
                ? ''
                : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
            sort: true,
            filter: true,
            type: 'date',
            method: 'and',
            childMethod: 'and',
            filterType: 'eq',
            defaultHidden: true,
            predefined: deepClone(datePredefined),
          },
          {
            _t_id: 'b7bcc3d8',
            prop: 'pickupDate',
            text: 'Pickup Date',
            computedText: (item) =>
              item === null
                ? ''
                : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
            sort: true,
            filter: true,
            type: 'date',
            method: 'and',
            childMethod: 'and',
            filterType: 'eq',
            defaultHidden: true,
            predefined: deepClone(datePredefined),
          },
          {
            _t_id: 'b7bcc554',
            prop: 'isConverted',
            text: 'Quote Status',
            computedText: (item) => (item ? 'Converted' : 'Unconverted'),
            sort: false,
            filter: false,
            defaultHidden: true,
          },
          {
            _t_id: 'customer_id_filter',
            filterType: 'eq',
            prop: 'customer/customerId',
            type: 'number',
            filterPlaceholder: true,
          },
          {
            _t_id: 'contract_id_filter_1',
            filterType: 'isnull',
            prop: 'contractId',
            type: 'number',
            filterPlaceholder: true,
          },
        ],
      },
    }
  },
  computed: {
    ...authComputed,
  },
  mounted() {
    this.tableProps.addNewHandler = () => {
      this.$router.push({ name: 'quotes.add' })
    }

    this.tableProps.columns.push(
      {
        _t_id: 'f4df7e64',
        prop: ['customer/bookings'],
        text: 'Customer Bookings Per Year',
        sort: true,
        filter: true,
        component: QuotesActionableColumn,
        type: 'number',
        action: 'CUSTOMER_DETAIL',
        detail: false,
        sortProp: 'customer/bookings',
        filterType: 'eq',
        childMethod: 'and',
        defaultHidden: true,
        predefined: deepClone(numericRangePredefined),
      },
      {
        _t_id: 'xb7bcc554',
        prop: 'isCharterUpQuote',
        text: 'Marketplace',
        computedText: (item) => (item ? 'Yes' : 'No'),
        sort: true,
        filter: true,
        filterType: 'eq',
        customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
        defaultHidden: true,
        predefined: [
          {
            text: 'Marketplace',
            controlType: 'default-repeat',
            refreshOnSelect: true,
            withValue: true,
            checkbox: true,
            value: 1,
            customEval: (activeFilter) => {
              if (!activeFilter.value) {
                activeFilter.value = true
              } else {
                activeFilter.value = undefined
              }
            },
          },
        ],
      }
    )

    this.tableProps.columns.push({
      _t_id: 'f4d9de64',
      prop: ['customer/organization'],
      text: 'Organization',
      sort: true,
      filter: true,
      component: QuotesActionableColumn,
      type: 'text',
      action: 'CUSTOMER_DETAIL',
      detail: false,
      displayType: 'action-item',
      sortProp: 'customer/organization',
      filterType: 'contains',
      childMethod: 'and',
      defaultHidden: true,
      predefined: textLike,
    })

    this.tableProps.columns.push({
      _t_id: 'b7bc93d8',
      prop: 'decisionDate',
      text: 'Decision Date',
      computedText: (item) =>
        item === null ? '' : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
      sort: true,
      filter: true,
      type: 'date',
      method: 'and',
      childMethod: 'and',
      filterType: 'eq',
      defaultHidden: true,
      predefined: deepClone(datePredefined),
    })

    this.tableProps.columns.push({
      _t_id: 'b7bk93d8',
      prop: 'participatingInEmailCampaigns',
      text: 'Email Campaign Enabled',
      computedText: (item) => (item ? 'Enabled' : 'Disabled'),
      sort: true,
      filter: true,
      type: 'text',
      method: 'and',
      childMethod: 'and',
      filterType: 'eq',
      defaultHidden: true,
      customFilterTabDisplay: (val) => {
        return val ? 'Open' : 'Closed'
      },
      predefined: [
        {
          text: 'Email Campaign Enabled',
          controlType: 'default-repeat',
          refreshOnSelect: true,
          withValue: true,
          checkbox: true,
          value: 1,
          customEval: (activeFilter) => {
            if (!activeFilter.value) {
              activeFilter.value = true
            } else {
              activeFilter.value = undefined
            }
          },
        },
      ],
    })

    this.tableProps.initialFilters = [
      {
        column: this.tableProps.columns.find(
          (column) => column._t_id === 'customer_id_filter'
        ),
        value: this.customerId,
        hideOnFilterBar: true,
      },
      {
        column: this.tableProps.columns.find(
          (column) => column._t_id === 'contract_id_filter_1'
        ),
        value: 1,
        hideOnFilterBar: true,
      },
    ]
  },
  methods: {
    receiveFilters(filters) {
      this.filters = filters
    },
    receiveSorts(sorts) {
      this.sorts = sorts
    },
    addFilter() {
      this.currentPage = 1
      this.refresh()
    },
    removeFilter() {
      this.currentPage = 1
      this.refresh(true)
    },
    refresh(immediate) {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      if (immediate) {
        this.getTable()
      } else {
        this.debounce = setTimeout(this.getTable, 500)
      }
    },
    handleDelete(targetRow) {
      this.tableProps.list = this.tableProps.list.filter(
        (row) => row.quoteId !== targetRow.quoteId
      )
    },
    async getTable() {
      const sorts = this.sorts.asQueryParams()
      const filters = this.filters.asQueryParams()
      const { company } = this.currentUser
      const params = {
        sorts,
        filters,
        pageSize: this.itemsPerPage,
        page: this.currentPage,
        companyId: company.companyId,
        quotes: 'company',
      }
      this.tableProps.loading = true
      const quoteData = await this.$store
        .dispatch('quotes/quotesListView', params)
        .catch((error) => {
          logger.error(error)
          this.tableProps.loading = false

          return false
        })

      if (quoteData) {
        this.tableProps.perPage = this.itemsPerPage
        this.tableProps.currentPage = this.currentPage
        this.tableProps.list = quoteData.data.resultList
        this.tableProps.list.forEach((item) => {
          item.deleteMe = this.handleDelete.bind(this)
        })
        this.tableProps.total = quoteData.data.count
        this.tableProps.loading = false
      }
    },
    sort(sortItem) {
      this.sorts.add(sortItem)
      this.refresh(true)
    },
    changePage(pagination) {
      this.currentPage = pagination.page
      this.itemsPerPage = pagination.rowsPerPage
      this.refresh()
    },
  },
}
</script>

<style lang="scss" scoped>
.tag {
  cursor: pointer;
}
</style>
<style scoped>
::v-deep .status-filters .status-filter-select {
  max-width: 350px !important;
}
</style>
