<template>
  <v-container fluid>
    <v-layout column sheet>
      <DataTable v-bind="tableProps" />
    </v-layout>
  </v-container>
</template>

<script>
import { DateTime, Duration } from 'luxon'
import DataTable from '@/components/DataTable.vue'
import ReservationActionableColumn from '@/components/ReservationActionableColumn.vue'
import PickupRadiusFilter from '@/components/PickupRadiusFilter.vue'
import { currencyFilter } from '@/utils/currency'
import { metersToMilesString } from '@/utils/distance'
import { deepClone } from '@/utils/deepClone'
import { authComputed } from '@/state/helpers'
import MultiMarketFilterVue from '@/components/MultiMarketFilter.vue'
import {
  calculatedValues,
  datePredefined,
  noFutureDatesPredefined,
  numericRangePredefined,
  textLike,
  userPredefined,
} from '@/utils/predefined'

const reservationStatusMap = [
  { text: 'Upcoming', value: 'upcoming' },
  { text: 'Started', value: 'started' },
  { text: 'Finished', value: 'finished' },
  { text: 'Cancelled', value: 'cancelled' },
  { text: 'Hold', value: 'hold' },
]
const referralStatusMap = [
  { text: 'Not Offered', value: 'not_offered' },
  { text: 'Offered', value: 'fully_offered' },
  { text: 'Accepted', value: 'fully_accepted' },
  { text: 'Confirmed', value: 'fully_confirmed' },
  { text: 'Offered', value: 'offered' },
  { text: 'Accepted', value: 'accepted' },
  { text: 'Rejected', value: 'rejected' },
  { text: 'Confirmed', value: 'confirmed' },
]
const paymentStatusMap = [
  { text: 'Unpaid', value: 'not_paid' },
  { text: 'Partially Paid', value: 'partially_paid' },
  { text: 'Fully Paid', value: 'fully_paid' },
]

export default {
  components: {
    DataTable,
  },
  props: {
    customerId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      debounce: undefined,
      filters: () => [],
      sorts: () => [],
      itemsPerPage: 10,
      page: 1,
      isAdmin: false,
      tableProps: {
        initialFilters: [],
        enableColumnConfig: true,
        enableSavedViews: true,
        enableStatusFilterToggle: true,
        total: 0,
        tableId: 'customer_details_reservation_table_view',
        currentPage: 1,
        perPage: 10,
        list: [],
        sort: this.sort,
        changePage: this.changePage,
        addFilter: this.addFilter,
        removeFilter: this.removeFilter,
        setSort: this.setSort,
        detailKeyId: 'reservationId',
        isDetailed: true,
        shareFilters: this.receiveFilters,
        shareSorts: this.receiveSorts,
        loading: true,
        isAdmin: this.isAdmin,
        rowClass: this.rowClass.bind(this),
        calculatedValues,
        columns: [],
      },
    }
  },
  computed: {
    ...authComputed,
  },
  async mounted() {
    const user = this.currentUser
    this.tableProps.isAdmin = user?.group?.key === 'admin'
    this.tableProps.enableExport = this.canExport()
    await this.getColumns()
    if (this.$route.query.reservationId) {
      const reservationIdFilter = {
        column: this.tableProps.columns.find(
          (column) => column._t_id === 'd7df53d5'
        ),
        value: this.$route.query.reservationId,
      }
      this.tableProps.initialFilters = [reservationIdFilter]
    }
    this.tableProps.initialFilters.push({
      column: this.tableProps.columns.find(
        (column) => column._t_id === 'customer_id_filter_1'
      ),
      value: this.customerId,
      hideOnFilterBar: true,
    })
  },
  methods: {
    receiveFilters(filters) {
      this.filters = filters
    },
    receiveSorts(sorts) {
      this.sorts = sorts
    },
    addFilter() {
      this.currentPage = 1
      this.refresh()
    },
    removeFilter() {
      this.currentPage = 1
      this.refresh(true)
    },
    refresh(immediate) {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      if (immediate) {
        this.getTable()
      } else {
        this.debounce = setTimeout(this.getTable, 500)
      }
    },
    async getColumns() {
      const columnsOut = []
      columnsOut.push(
        {
          _t_id: 'b1cb186f-f5c6-45d2-b1d1-6755a86091ca',
          prop: 'managedId',
          component: ReservationActionableColumn,
          displayType: 'clickable-link',
          action: 'NOOP',
          text: 'ID',
          sort: true,
          filter: true,
          type: 'text',
          filterType: 'eq',
          sortProp: 'managedId',
          defaultSort: true,
        },
        {
          _t_id: '45d9c245-0449-459f-91d4-f6f55922785b',
          prop: 'driverInfoEmailSent',
          text: 'Driver Info Email Sent',
          sort: true,
          filter: false,
          type: 'text',
          computedText: (item) => (item ? 'Sent' : 'Not Sent'),
          method: 'and',
          filterType: 'contains',
          defaultHidden: true,
        },
        {
          _t_id: '03f93359-a07a-4083-a44c-6140029e3872',
          prop: 'customer/industries',
          text: 'Industries',
          computedText: (item, row) =>
            item ? item.replaceAll('||', ' / ') : '',
          sort: true,
          sortProp: 'trip/customer/industries',
          filterProp: 'trip/customer/industries',
          filter: true,
          type: 'text',
          method: 'and',
          childMethod: 'and',
          filterType: 'contains',
          defaultHidden: true,
        },
        {
          _t_id: 'c9eae381-65b7-4e24-a12b-4f42ec9f49e0',
          prop: 'isCharterUpQuote',
          sortProp: 'trip.quote.isCharterUpQuote',
          filterProp: 'trip.quote.isCharterUpQuote',
          text: 'Marketplace',
          computedText: (item) => (item ? 'Yes' : 'No'),
          customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
          sort: true,
          filter: true,
          filterType: 'eq',
          defaultHidden: true,
          predefined: [
            {
              text: 'Marketplace',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              checkbox: true,
              value: 1,
              customEval: (activeFilter) => {
                if (!activeFilter.value) {
                  activeFilter.value = true
                } else {
                  activeFilter.value = undefined
                }
              },
            },
          ],
        },
        {
          _t_id: '1b5e8b2d-3723-4b9e-b5dd-197d9c3f3b73',
          prop: ['customer/industryDescription'],
          text: 'Industry Description',
          sort: true,
          filter: true,
          detail: false,
          type: 'text',
          filterType: 'contains',
          sortProp: 'trip.customer.industryDescription',
          filterProp: 'trip.customer.industryDescription',
          defaultHidden: true,
        }
      )

      // Standard columns
      columnsOut.push(
        {
          _t_id: '27371c1c-07cf-464c-8bbc-821a630b9daf',
          prop: 'startDate',
          text: 'Pickup Date',
          computedText: (item) => DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          predefined: deepClone(datePredefined),
        },
        {
          _t_id: '6371bf66-3119-431a-8cf3-650a507cc32c',
          prop: 'customerName',
          text: 'Customer',
          sort: true,
          filter: true,
          type: 'text',
          action: 'CUSTOMER_DETAIL',
          detail: false,
          displayType: 'action-item',
          sortProp: 'customerName',
          filterType: 'contains',
          childMethod: 'and',
          predefined: textLike,
        },
        {
          _t_id: '14ca9cc6-2ec4-4c07-a222-b9ea2ea91fc5',
          prop: 'customerTotal',
          text: 'Customer Total',
          computedText: this.customerTotalComputedText,
          sort: true,
          filter: true,
          type: 'number',
          component: ReservationActionableColumn,
          displayType: 'info-icon',
          sortProp: 'trip.total',
          filterProp: 'trip.total',
          filterType: 'eq',
          childMethod: 'and',
          predefined: deepClone(numericRangePredefined),
        },
        {
          _t_id: 'customer_id_filter_1',
          filterType: 'eq',
          prop: 'customerId',
          filterProp: 'trip.customer.customerId',
          text: 'Customer Id',
          type: 'text',
          childMethod: 'and',
          filter: true,
          filterPlaceholder: true,
        }
      )

      columnsOut.push(
        {
          _t_id: 'dbee7e9b-6518-42b5-a6c8-0dc739c23633',
          prop: 'assignedDriverPercentage',
          text: 'Driver',
          computedText: this.assignedDriverComputedText,
          sort: false,
          filter: false,
          detail: false,
          action: 'ASSIGNED_DRIVER_VEHICLE_DETAIL_BROKER',
          type: 'number',
          component: ReservationActionableColumn,
          displayType: 'info-icon',
          sortProp: 'trip.total',
          filterProp: 'trip.total',
          filterType: 'eq',
          childMethod: 'and',
        },
        {
          _t_id: 'f79bc42b-5d40-4fb7-a9dc-ddbb8c466199',
          prop: 'assignedVehiclePercentage',
          text: 'Vehicle',
          computedText: this.assignedVehicleComputedText,
          sort: false,
          filter: false,
          detail: false,
          action: 'ASSIGNED_DRIVER_VEHICLE_DETAIL_BROKER',
          type: 'number',
          component: ReservationActionableColumn,
          displayType: 'info-icon',
          sortProp: 'trip.total',
          filterProp: 'trip.total',
          filterType: 'eq',
          childMethod: 'and',
        }
      )

      columnsOut.push({
        _t_id: '37edef27-04da-4ce1-8350-bd983cc63ace',
        prop: ['bookedByFirstName', 'bookedByLastName'],
        text: 'Booked By',
        sort: true,
        filter: true,
        detail: false,
        type: 'text',
        action: 'BOOKED_BY_DETAIL',
        displayType: 'action-item',
        sortProp: 'bookedByLastName',
        filterType: 'contains',
        childMethod: 'and',
        predefined: userPredefined,
      })

      columnsOut.push({
        _t_id: '4ac0c481-57ed-4e38-947a-d3c3da3cfeb3',
        prop: '/',
        text: 'Referred To',
        sort: true,
        filter: true,
        detail: false,
        type: 'text',
        list: 'referredTo',
        listProp: 'name',
        component: ReservationActionableColumn,
        displayType: 'list',
        action: 'REFERRED_TO_DETAIL',
        sortProp: 'referrals.company.name',
        filterProp: 'referrals.company.name',
        filterType: 'contains',
        childMethod: 'or',
        predefined: textLike,
      })

      columnsOut.push(
        {
          _t_id: 'f35fa915-9ebf-4a03-a819-586ceeb2ac4c',
          prop: 'openForBid',
          text: 'Open For Bid',
          sort: true,
          filter: true,
          detail: false,
          type: 'text',
          defaultHidden: true,
          sortProp: 'trip.openForBid',
          filterProp: 'trip.openForBid',
          filterType: 'eq',
          computedText: (item) => {
            return item ? 'Open' : 'Closed'
          },
          customFilterTabDisplay: (val) => {
            return val ? 'Open' : 'Closed'
          },
          predefined: [
            {
              text: 'Open For Bid',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              checkbox: true,
              value: 1,
              customEval: (activeFilter) => {
                if (!activeFilter.value) {
                  activeFilter.value = true
                } else {
                  activeFilter.value = undefined
                }
              },
            },
          ],
        },
        {
          _t_id: 'b0887818-587f-4f1f-9cf1-c843800a980f',
          prop: 'driverInfoEmailSent',
          text: 'Driver Info Email Sent',
          sort: true,
          filter: false,
          type: 'text',
          computedText: (item) => (item ? 'Sent' : 'Not Sent'),
          method: 'and',
          filterType: 'contains',
          defaultHidden: true,
        },
        {
          _t_id: 'cc6f5aa3-2e4b-4d9b-9be9-ff1212371310',
          prop: 'isCharterUpQuote',
          sortProp: 'trip.quote.isCharterUpQuote',
          filterProp: 'trip.quote.isCharterUpQuote',
          text: 'Marketplace',
          computedText: (item) => (item ? 'Yes' : 'No'),
          customFilterTabDisplay: (item) => (item ? 'Yes' : 'No'),
          sort: true,
          filter: true,
          filterType: 'eq',
          defaultHidden: true,
          predefined: [
            {
              text: 'Marketplace',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              withValue: true,
              checkbox: true,
              value: 1,
              customEval: (activeFilter) => {
                if (!activeFilter.value) {
                  activeFilter.value = true
                } else {
                  activeFilter.value = undefined
                }
              },
            },
          ],
        }
      )

      columnsOut.push(
        // more standard columns
        {
          _t_id: '72cf3f13-6a35-4ea7-8858-36cb95e533bb',
          prop: 'quoteId',
          text: 'Quote ID',
          sort: true,
          filter: true,
          type: 'number',
          method: 'and',
          filterType: 'eq',
          defaultHidden: true,
        },
        {
          _t_id: '77c1805e-0613-4d04-8367-ff03644fc22e',
          prop: 'dueDate',
          sortProp: 'trip.dueDate',
          filterProp: 'trip.dueDate',
          text: 'Due Date',
          computedText: (item) =>
            item === null ? '' : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          predefined: deepClone(datePredefined),
          defaultHidden: true,
        },
        {
          _t_id: '6d49027c-336d-48b7-aaab-6f70d6b87130',
          prop: 'tripTypeLabel',
          sortProp: 'trip.tripType.label',
          filterProp: 'trip.tripType.label',
          text: 'Trip Type',
          sort: true,
          filter: true,
          type: 'text',
          method: 'and',
          filterType: 'contains',
          defaultHidden: true,
        },
        {
          _t_id: 'cb6ffa4f-000e-41a7-838b-cbcdc8768583',
          prop: 'referralAmount',
          text: 'Referral Total',
          computedText: this.referralTotalComputedText,
          component: ReservationActionableColumn,
          displayType: 'info-icon',
          sort: true,
          filter: true,
          defaultHidden: true,
          type: 'number',
          sortProp: 'referralAmount',
          filterProp: 'referralAmount',
          filterType: 'contains',
        },
        {
          _t_id: '210f6e4d-0f97-48de-a146-9a85d54112bb',
          prop: 'referralStatus',
          text: 'Referral Status',
          computedText: (item) => this.mapCategoryText(referralStatusMap, item),
          sort: true,
          filter: false,
          defaultHidden: true,
          type: 'text',
          filterType: 'contains',
        },
        {
          _t_id: 'bd5062c1-ef49-4172-b1f7-9677cfe58860',
          prop: 'reservationStatus',
          text: 'Reservation Status',
          computedText: (item) =>
            this.mapCategoryText(reservationStatusMap, item),
          sort: true,
          filter: false,
          defaultHidden: true,
          type: 'text',
          filterType: 'contains',
        },
        {
          _t_id: '34baa712-7d27-4fbc-bc1d-cc7f33e541aa',
          prop: 'paymentStatus',
          text: 'Payment Status',
          computedText: (item) => this.mapCategoryText(paymentStatusMap, item),
          sort: true,
          filter: false,
          defaultHidden: true,
          type: 'text',
          filterType: 'contains',
        },
        {
          _t_id: '609e7c63-5cc1-49a3-b502-ac3208b74bed',
          prop: 'referralPaymentStatus',
          text: 'Referral Payment Status',
          computedText: (item) => this.mapCategoryText(paymentStatusMap, item),
          sort: true,
          filter: false,
          defaultHidden: true,
          type: 'text',
          filterType: 'contains',
        },
        {
          _t_id: '8f42b072-84f0-43c8-a8bb-6ef3b71ffd75',
          prop: 'firstStopAddress',
          text: 'Pickup Location',
          sort: true,
          filter: true,
          defaultHidden: true,
          type: 'text',
          filterType: 'contains',
          childMethod: 'or',
          unset: ['6b01a5c0', 'c22dbed8'],
          predefined: [
            {
              text: 'Radius',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              controls: [
                {
                  id: '9522531a',
                  text: 'Location Radius',
                  filterType: 'radius',
                  component: PickupRadiusFilter,
                },
              ],
            },
            {
              text: 'Multiple Markets',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              controls: [
                {
                  id: '37e10ab1',
                  text: 'Multiple Markets',
                  filterType: 'contains',
                  component: MultiMarketFilterVue,
                },
              ],
            },
            {
              text: 'Search',
              controlType: 'default-repeat',
              refreshOnSelect: true,
              controls: [
                {
                  text: 'Search',
                  filterType: 'contains',
                },
              ],
            },
          ],
        },
        {
          _t_id: 'a4f6ec6b-3ca7-4f80-bb6d-2c255652a47a',
          prop: 'balance',
          text: 'Balance',
          computedText: (item) => currencyFilter(item),
          component: ReservationActionableColumn,
          displayType: 'action-item',
          sort: true,
          filter: true,
          defaultHidden: true,
          type: 'number',
          filterType: 'gt',
          childMethod: 'and',
          predefined: deepClone(numericRangePredefined),
        },
        {
          _t_id: '001cbf41-5fe7-4347-b4d3-180dbcf28bd0',
          prop: 'distance',
          filterProp: 'trip.distance',
          sortProp: 'trip.distance',
          text: 'Distance',
          computedText: (item) => metersToMilesString(item),
          sort: true,
          filter: true,
          defaultHidden: true,
          type: 'number',
          filterType: 'contains',
          childMethod: 'and',
          predefined: deepClone(numericRangePredefined),
        },
        {
          _t_id: 'c3677179-eba3-41d2-99d6-8044844d67c9',
          prop: 'drivingTime',
          filterProp: 'trip.drivingTime',
          sortProp: 'trip.drivingTime',
          text: 'Driving Time',
          computedText: (item) =>
            `${Number(
              Duration.fromObject({ seconds: item }.as('hours'))
            ).toFixed(2)} Hrs`,
          sort: true,
          filter: true,
          defaultHidden: true,
          type: 'number',
          filterType: 'contains',
          childMethod: 'and',
          predefined: deepClone(numericRangePredefined),
        },
        {
          _t_id: 'f67c542a-1021-48c9-a30e-644d9ad1804b',
          prop: 'firstSentDate',
          text: 'Referred Date',
          computedText: (item) =>
            item === null ? '' : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          predefined: deepClone(noFutureDatesPredefined),
          defaultHidden: true,
        },
        {
          _t_id: 'de891030-caad-41ea-885b-b65ae5e58828',
          prop: 'lastDropoffDate',
          text: 'Dropoff Date',
          computedText: (item) =>
            item === null ? '' : DateTime.fromISO(item).toFormat('MM/dd/yyyy'),
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          defaultHidden: true,
          predefined: deepClone(datePredefined),
        },
        {
          _t_id: '06689235-9184-4336-a3ce-bb05fed96075',
          prop: 'cancelledOn',
          text: 'Cancelled Date',
          computedText: (item) =>
            item ? DateTime.fromISO(item).toFormat('MM/dd/yyyy') : '',
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          defaultHidden: true,
          predefined: deepClone(datePredefined),
        },
        {
          _t_id: '6207495f-da9f-4fe1-971e-380fcaa748ff',
          prop: 'createdOn',
          text: 'Created Date',
          computedText: (item) =>
            item ? DateTime.fromISO(item).toFormat('MM/dd/yyyy') : '',
          sort: true,
          filter: true,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          defaultHidden: true,
          predefined: deepClone(datePredefined),
        },
        {
          _t_id: '10e493c2-06fa-4ff5-959c-3915e6562629',
          prop: 'distanceFromMarket',
          filterType: '',
          filterPlaceholder: true,
        },
        {
          _t_id: '4ae0a5d4-c283-4a87-a642-f23c9a131cc2',
          prop: 'marketId',
          filterType: 'eq',
          filterPlaceholder: true,
        },
        {
          _t_id: '74d530f8-5330-4ba6-861e-cbeb53847d44',
          prop: 'nearestMarketId',
          filterType: 'eq',
          filterPlaceholder: true,
        },
        {
          _t_id: '19fbc3bb-b6f5-4d6f-adc9-da08fb82ddae',
          prop: ['firstPickupAddress/addressName'],
          text: 'First Pickup Address',
          sort: true,
          filter: true,
          detail: false,
          type: 'text',
          filterType: 'contains',
          sortProp: 'trip.firstStopAddress.addressName',
          filterProp: 'trip.firstStopAddress.addressName',
          defaultHidden: true,
        },
        {
          _t_id: 'b9612be6-83e2-497b-a992-a4b9fec3e121',
          prop: ['firstDropoffAddress/addressName'],
          text: 'First Dropoff Address',
          sort: true,
          filter: true,
          detail: false,
          type: 'text',
          filterType: 'contains',
          sortProp: 'trip.firstDropoffAddress.addressName',
          filterProp: 'trip.firstDropoffAddress.addressName',
          defaultHidden: true,
        },
        {
          _t_id: 'b1817521-3927-4b03-b4e0-51405a977a6f',
          prop: 'contractId',
          text: 'Contract ID',
          sort: true,
          filter: true,
          filterProp: 'trip.quote.contractId',
          sortProp: 'trip.quote.contractId',
          type: 'text',
          method: 'or',
          childMethod: 'and',
          filterType: 'eq',
          defaultHidden: true,
        },
        {
          _t_id: 'aa3d9c26-86b0-4677-a5e7-798bcc81f495',
          prop: 'customer/customerAccount/name',
          text: 'Customer Account',
          sort: true,
          filter: true,
          type: 'text',
          detail: false,
          sortProp: 'trip/customer/customerAccount/name',
          filterProp: 'trip/customer/customerAccount/name',
          filterType: 'contains',
          childMethod: 'and',
          defaultHidden: true,
        },
        {
          _t_id: '796e0031-cc24-49fb-9572-abed123c2978',
          prop: 'bookingsPerYear',
          text: 'Bookings Per Year',
          sort: true,
          filter: true,
          type: 'number',
          filterType: 'eq',
          sortProp: 'bookingsPerYear',
          predefined: deepClone(numericRangePredefined),
          computedText: (item) => item || '0',
          defaultHidden: true,
        },
        {
          _t_id: 'a7b7a82f-bf66-4a04-ae30-646c0b32da53',
          prop: 'organization',
          text: 'Organization',
          sort: true,
          filter: true,
          type: 'text',
          detail: false,
          sortProp: 'organization',
          filterType: 'contains',
          childMethod: 'and',
          defaultHidden: true,
          predefined: textLike,
        },
        {
          _t_id: 'e240369c-7938-4069-8960-4f0508c41ed8',
          prop: 'customerEmail',
          text: 'Customer Email',
          sort: true,
          filter: true,
          type: 'text',
          detail: false,
          sortProp: 'customerEmail',
          filterType: 'contains',
          childMethod: 'and',
          defaultHidden: true,
          predefined: textLike,
        }
      )
      if (this.canViewGrossProfit) {
        columnsOut.push({
          _t_id: '21e3164b-4c05-4d71-ae2d-efc1f101fa84',
          prop: 'grossProfit',
          text: 'Gross Profit',
          computedText: (item) => currencyFilter(item),
          sort: true,
          filter: true,
          defaultHidden: true,
          type: 'number',
          filterType: 'gt',
          childMethod: 'and',
          predefined: deepClone(numericRangePredefined),
        })
      }

      this.tableProps.columns = columnsOut
    },
    async getTable() {
      const sorts = this.sorts.asQueryParams()
      const filters = this.filters.asQueryParams()
      const companyId = this.currentUser?.companyId
      const params = {
        sorts,
        filters,
        companyId,
        pageSize: this.itemsPerPage,
        page: this.currentPage,
        reservations: 'company',
      }
      this.tableProps.loading = true
      const reservationData = await this.$store.dispatch(
        'reservations/reservationsTableView',
        params
      )
      const reservations = reservationData.data
      this.tableProps.perPage = this.itemsPerPage
      this.tableProps.currentPage = this.currentPage
      this.tableProps.list = reservations.resultList
      this.tableProps.total = reservations.count
      this.tableProps.loading = false
    },
    sort(sortItem) {
      this.sorts.add(sortItem)
      this.refresh(true)
    },
    changePage(pagination) {
      this.currentPage = pagination.page
      this.itemsPerPage = pagination.rowsPerPage
      this.refresh()
    },
    mapCategoryText(map, item) {
      const match = map.find((status) => status.value === item)
      if (match) {
        return match.text
      }
      return item
    },
    customerTotalComputedText(row, column, action) {
      if (!row.customerTotal) {
        return
      }
      const { paymentStatus, customerTotal, balance, dueDate } = row
      const isPaymentOverdue = DateTime.local() > dueDate

      function createTooltipHTML() {
        const paymentStatusHeaderMap = {
          not_paid: `Unpaid${isPaymentOverdue ? ' (Overdue)' : ''}`,
          partially_paid: 'Partially Paid',
          fully_paid: 'Paid in Full',
        }

        return `<h6>${paymentStatusHeaderMap[paymentStatus || 'not_paid']}</h6>
          <p>Total Owed: ${currencyFilter(customerTotal)}${
          paymentStatus !== 'fully_paid'
            ? `<br />Balance Due: ${currencyFilter(balance)}`
            : ''
        }</p>`
      }

      function getIconValue() {
        const iconMap = {
          not_paid: 'unpaid',
          partially_paid: 'unpaid',
          fully_paid: 'full_payment',
        }
        return iconMap[paymentStatus]
      }

      function getIconColor() {
        const colorMap = {
          not_paid: 'error',
          partially_paid: 'warning',
          fully_paid: 'success',
        }

        return colorMap[paymentStatus]
      }

      const actionMap = {
        tooltip: createTooltipHTML.bind(this),
        icon: getIconValue.bind(this),
        iconcolor: getIconColor,
        total: currencyFilter.bind(this, customerTotal),
      }
      const actionFunction = actionMap[action || 'total']
      return actionFunction()
    },
    referralTotalComputedText(row, column, action) {
      const { referralPaymentStatus, referralBalance, referralAmount } = row
      const numericReferralBalance =
        typeof referralBalance === 'number' ? referralBalance : 0
      function createTooltipHTML() {
        const paymentStatusHeaderMap = {
          not_paid: 'Unpaid',
          partially_paid: 'Partially Paid',
          fully_paid: 'Paid in Full',
        }

        return `<h6>${
          paymentStatusHeaderMap[referralPaymentStatus || 'not_paid']
        }</h6>
          <p>Total Owed: ${currencyFilter(referralAmount)}${
          referralPaymentStatus !== 'fully_paid'
            ? `<br />Balance Due: ${currencyFilter(numericReferralBalance)}`
            : ''
        }</p>`
      }

      function getIconValue() {
        const iconMap = {
          not_paid: 'unpaid',
          partially_paid: 'unpaid',
          fully_paid: 'full_payment',
        }
        return iconMap[referralPaymentStatus]
      }

      function getIconColor() {
        const colorMap = {
          not_paid: 'error',
          partially_paid: 'warning',
          fully_paid: 'success',
        }

        return colorMap[referralPaymentStatus]
      }

      const actionMap = {
        tooltip: createTooltipHTML.bind(this),
        icon: getIconValue.bind(this),
        iconcolor: getIconColor,
        total: currencyFilter.bind(this, referralAmount),
      }
      const actionFunction = actionMap[action || 'total']
      return actionFunction()
    },
    assignedDriverComputedText(row, column, action) {
      const { assignedDriverPercentage } = row

      function createTooltipHTML() {
        const tooltipText =
          assignedDriverPercentage >= 100
            ? 'Fully Assigned'
            : 'Needs Assignment'
        return `<h6>${tooltipText}</h6>`
      }

      function getIconValue() {
        return 'drivers'
      }

      function getIconColor() {
        return assignedDriverPercentage >= 100 ? 'success' : 'error'
      }

      const actionMap = {
        tooltip: createTooltipHTML.bind(this),
        icon: getIconValue.bind(this),
        iconcolor: getIconColor,
        noop: () => '',
      }
      const actionFunction = actionMap[action || 'noop']
      return actionFunction()
    },
    assignedVehicleComputedText(row, column, action) {
      const { assignedVehiclePercentage } = row

      function createTooltipHTML() {
        const tooltipText =
          assignedVehiclePercentage >= 100
            ? 'Fully Assigned'
            : 'Needs Assignment'
        return `<h6>${tooltipText}</h6>`
      }

      function getIconValue() {
        return 'vehicles'
      }

      function getIconColor() {
        return assignedVehiclePercentage >= 100 ? 'success' : 'error'
      }

      const actionMap = {
        tooltip: createTooltipHTML,
        icon: getIconValue,
        iconcolor: getIconColor,
        noop: () => '',
      }
      const actionFunction = actionMap[action || 'noop']
      return actionFunction()
    },
    rowClass(rowProps) {
      const { item } = rowProps
      if (
        item.balance > 0 &&
        DateTime.local() > DateTime.fromISO(item.dueDate)
      ) {
        return ' error-background'
      }
    },
    canExport() {
      const roles = this.currentUserProfile?.roles || []
      const canExportRole = roles.find(
        (r) => r.roleName === 'can_export_reservations'
      )
      return !!canExportRole
    },
  },
}
</script>
