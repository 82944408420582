<template>
  <BaseTableWrapper
    :special-text-formatting="specialTextFormatting"
    :table-init="tableInit"
    :is-admin="isAdmin"
    :columns="columns"
    :initial-filters="initialFilters"
  >
    <h1 class="page-header"></h1>
  </BaseTableWrapper>
</template>

<script>
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import { friendlyDate } from '@/utils/time'
import { authComputed } from '@/state/helpers'
import { DateTime } from 'luxon'
import { calculatedValues, datePredefined } from '@/utils/predefined'
import { deepClone } from '@/utils/deepClone'

export default {
  components: {
    BaseTableWrapper,
  },
  props: {
    customerId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      initialFilters: [],
      isAdmin: this.isAdmin,
      specialTextFormatting: {
        createdAt: friendlyDate,
      },
      columns: [],
      tableInit: {
        enableSavedViews: false,
        enableStatusFilterToggle: true,
        enableColumnConfig: false,
        detailKeyId: 'customerAuditId',
        tableId: 'customer_details_audits_tv_view',
        addNewEnabled: false,
        currentPage: 1,
        perPage: 10,
        calculatedValues,
        initialFilters: [],
        columns: [],
        collection: 'CustomerAudits',
        additionalQueries: this.customerId,
      },
    }
  },
  computed: {
    ...authComputed,
  },
  async mounted() {
    const user = this.currentUser
    this.isAdmin = user?.group?.key === 'admin'
    await this.getColumns()
  },
  methods: {
    async getColumns() {
      const columnsOut = [
        {
          _t_id: 'c2f2f50b-3d0a-4136-a083-74e9787ab8e6',
          prop: 'customerAuditId',
          text: 'Audit ID',
          sort: true,
          filter: false,
          type: 'number',
          sortProp: 'customerAuditId',
          filterType: 'eq',
          defaultSort: true,
        },
        {
          _t_id: 'a92dd2cc-f6be-4766-a373-6f50ed95f71b',
          prop: ['user/firstName', 'user/lastName'],
          text: 'User',
          sort: true,
          filter: false,
          type: 'text',
          sortProp: 'user/lastName',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
        },
        {
          _t_id: '68779009-f4d0-42b2-9b62-6271fc6180ab',
          prop: 'datetime',
          text: 'Time',
          computedText: (item) =>
            DateTime.fromISO(item).toFormat('MM/dd/yyyy HH:mm'),
          sort: true,
          filter: false,
          type: 'date',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          predefined: deepClone(datePredefined),
        },
        {
          _t_id: 'ef378ac0-6859-4957-a804-d3a5e65a8a15',
          prop: 'actionType',
          text: 'Action Type',
          sort: true,
          filter: false,
          type: 'text',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          sortProp: 'actionType',
        },
        {
          _t_id: '4114625d-f396-489b-a6f6-f333f4f4fe43',
          prop: 'previousValue',
          text: 'Previous Value',
          sort: true,
          filter: false,
          type: 'text',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          sortProp: 'previousValue',
        },
        {
          _t_id: '1ca6b76a-1442-4041-9582-34d4ba47df92',
          prop: 'newValue',
          text: 'New Value',
          sort: true,
          filter: false,
          type: 'text',
          method: 'and',
          childMethod: 'and',
          filterType: 'eq',
          sortProp: 'newValue',
        },
      ]

      return (this.columns = columnsOut)
    },
  },
}
</script>
