<template>
  <div>
    <v-btn-toggle
      v-model="active"
      mandatory
      class="table-toggle elevation-0 mt-2"
      style="border-radius: 5px; z-index: 2"
    >
      <v-btn class="trip-details-button-toggle" flat @click="toggleSubTabs = 0">
        Quotes
      </v-btn>
      <v-btn
        class="trip-details-button-toggle"
        style="border-radius: 0px"
        flat
        @click="toggleSubTabs = 1"
      >
        Reservations
      </v-btn>
      <v-btn
        v-if="canViewTickets"
        class="trip-details-button-toggle"
        style="border-radius: 0px"
        flat
        @click="toggleSubTabs = 2"
      >
        Tickets
      </v-btn>
      <v-btn
        class="trip-details-button-toggle"
        style="border-radius: 0px"
        flat
        @click="toggleSubTabs = 3"
      >
        CRM
      </v-btn>
      <v-btn
        class="trip-details-button-toggle"
        style="border-radius: 0px"
        flat
        @click="toggleSubTabs = 4"
      >
        Logs
      </v-btn>
    </v-btn-toggle>
    <CustomerDetailQuoteList
      v-if="customerId"
      v-show="toggleSubTabs === 0"
      :customer-id="customerId"
      class="table-style"
    />
    <CustomerDetailReservationList
      v-if="customerId"
      v-show="toggleSubTabs === 1"
      :customer-id="customerId"
      class="table-style"
    />
    <CustomerDetailTicketList
      v-if="customerId && canViewTickets"
      v-show="toggleSubTabs === 2"
      :customer-id="customerId"
      class="table-style"
      style="margin-top: -20px; z-index: 0"
    />
    <CustomerDetailCRM
      v-if="customerId"
      v-show="toggleSubTabs === 3"
      :customer-id="customerId"
      class="table-style"
      style="margin-top: -20px; z-index: 0"
      @refresh-audit-table="refreshAuditTable"
    />
    <CustomerAudits
      v-if="customerId"
      v-show="toggleSubTabs === 4"
      :key="auditComponentKey"
      :customer-id="customerId"
      class="table-style"
      style="margin-top: -20px; z-index: 0"
    />
  </div>
</template>

<script>
import CustomerDetailQuoteList from './CustomerDetailQuoteList.vue'
import CustomerDetailReservationList from './CustomerDetailReservationList.vue'
import CustomerDetailTicketList from './CustomerDetailTicketList.vue'
import CustomerDetailCRM from '@/components/CustomerDetailCRM.vue'
import CustomerAudits from '@/components/CustomerAuditList.vue'
import { authComputed } from '@/state/helpers'

export default {
  components: {
    CustomerDetailQuoteList,
    CustomerDetailReservationList,
    CustomerDetailTicketList,
    CustomerDetailCRM,
    CustomerAudits,
  },
  props: {
    customerId: {
      type: String,
      default: null,
      auditComponentKey: 0,
    },
  },
  data() {
    return {
      active: null,
      toggleSubTabs: 0,
      canViewTickets: false,
    }
  },
  computed: {
    ...authComputed,
  },
  mounted() {
    this.canViewTickets = this.hasPermission('canViewAllTickets')
  },
  methods: {
    refreshAuditTable() {
      this.auditComponentKey += 1
    },
  },
}
</script>

<style lang="scss" scoped>
.trip-details-button-toggle {
  border: 1px solid $gray-light !important;
  padding: 20px 15px;
}

::v-deep {
  .table-toggle {
    .v-btn:first-child {
      border-radius: 5px 0 0 5px !important;
    }

    .v-btn:last-child {
      border-radius: 0 5px 5px 0 !important;
    }
  }
}

::v-deep {
  .table-style {
    padding: 0;

    div {
      padding: 0;
      div {
        div {
          .v-table__overflow {
            border-top: 1px solid $gray-light !important;
          }
          .v-datatable {
            border-left: 1px solid $gray-light !important;
            border-bottom: 1px solid $gray-light !important;
            border-right: 1px solid $gray-light !important;
          }
        }
      }
    }
  }
}
</style>
